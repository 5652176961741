import React, { useContext, useState } from "react";
import "./skillsSection.scss";
import skillsBoard from "../../../../assets/img/skillsboard.svg";
import skillsBottom from "../../../../assets/img/skillsbottom.svg";
import mushRooms from "../../../../assets/img/mushrooms.png";
import skillsBackDrop from "../../../../assets/img/skillsbackdrop1.jpg";
import { MySkills } from "../../../../helpers/skills";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { NavItemContext } from "../../../../helpers/context";

function SkillsSection(props) {
  const navigate = useNavigate();

  const [skills, setSkills] = useState(MySkills);
  const [skillType, setSkillType] = useState("all");

  const { navItem, setNavItem } = useContext(NavItemContext);

  const { ref, inView, entry } = useInView({
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && navItem == undefined) {
        navigate("#skills");
      } else if (navItem == "skills") {
        navigate("#skills");
        setNavItem(undefined);
      }
    },
  });

  const changeSkillList = (category) => {
    setSkillType(category);
    if (category != "all") {
      const a = MySkills.filter((item) => item.category.includes(category));
      setSkills(a);
    } else {
      setSkills(MySkills);
    }
  };

  const animVariant = {
    initial: { opacity: 0, translateY: 20 },
    whileInView: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className="SkillsSection" id="skillsss">
      {/* <img src={skillsBackDrop} className="skillsbackdrop-img" alt="" /> */}
      <div className="skills-top-bar"></div>
      <div className="section-container">
        <dev className="skillsboard-img">
          <img src={skillsBoard} alt="" />
          <div className="section-title">
            <span>S</span> kill <span>s</span>
          </div>
        </dev>
        <div className="skills-nav" ref={ref}>
          <ul>
            <li
              onClick={() => {
                changeSkillList("all");
              }}
              className={skillType == "all" ? "active-skills-nav-item" : ""}
            >
              All
            </li>
            <li
              onClick={() => {
                changeSkillList("web");
              }}
              className={skillType == "web" ? "active-skills-nav-item" : ""}
            >
              Web
            </li>
            <li
              onClick={() => {
                changeSkillList("mobile");
              }}
              className={skillType == "mobile" ? "active-skills-nav-item" : ""}
            >
              Mobile
            </li>
            <li
              onClick={() => {
                changeSkillList("desktop");
              }}
              className={skillType == "desktop" ? "active-skills-nav-item" : ""}
            >
              Desktop
            </li>
          </ul>
        </div>
        <motion.div
          className="skill-card-container"
          variants={animVariant}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
        >
          {skills.map((skill) => {
            return (
              <div className="skill-card">
                <img src={skill.img} alt="" />
                <div className="card-title">{skill.name}</div>
              </div>
            );
          })}
        </motion.div>
      </div>
      <img className="skillsbottom-img" src={skillsBottom} alt="" />
      <img src={mushRooms} className="mushrooms-img" alt="" />
    </div>
  );
}

export default SkillsSection;
