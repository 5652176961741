import React, { useContext } from "react";
import "./aboutSection.scss";
import propic from "../../../../assets/img/propic.webp";
import tunell from "../../../../assets/img/tunell.svg";
import tunelr from "../../../../assets/img/tunelr.svg";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { NavItemContext } from "../../../../helpers/context";
import { Tooltip } from "react-tooltip";

function AboutSection() {
  const navigate = useNavigate();

  const { navItem, setNavItem } = useContext(NavItemContext);

  const { ref, inView, entry } = useInView({
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && navItem == undefined) {
        navigate("#about");
      } else if (navItem == "about") {
        navigate("#about");
        setNavItem(undefined);
      }
    },
  });

  const animVariant = {
    initial: { opacity: 0, translateY: 50 },
    whileInView: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 1,
        delay: 0.1,
      },
    },
  };

  return (
    <div className="AboutSection">
      <div className="section-container">
        <div className="about-container">
          <div className="about-card">
            <motion.div
              className="div-wrapper"
              variants={animVariant}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
            >
              <div className="spacer-t"></div>
              <div className="section-title">
                About <span>Me</span>
              </div>
              <div
                className="pro-pic"
                data-tooltip-id="propic-tooltip"
                data-tooltip-content="Hi.. This is me 😃"
                ref={ref}
              >
                <img src={propic} alt="" />
              </div>
              <Tooltip id="propic-tooltip" place="left" />
              <div className="rel-links">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/prasannasampath.sampath.73/" target="_blank">
                      <i class="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@SihinaCode" target="_blank">
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://github.com/ISampathI" target="_blank">
                      <i class="fa-brands fa-github"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/prasanna-sampath-96106226a/"
                      target="_blank"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="spacer-b"></div>
            </motion.div>
          </div>
          <div className="tunel-img tunell-img" alt=""></div>
          <div className="about-content-wrapper">
            <div className="tunelr-img" alt=""></div>
            <div className="about-content">
              <motion.div
                className="div-wrapper"
                variants={animVariant}
                initial="initial"
                whileInView="whileInView"
                viewport={{ once: true }}
              >
                <div className="min-about-card">
                  <div className="spacer-t"></div>
                  <div className="section-title">
                    About <span>Me</span>
                  </div>
                  <div className="propic-wrap">
                    <div className="pro-pic">
                      <img src={propic} alt="" />
                    </div>
                    <div className="rel-links">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/prasannasampath.sampath.73/"
                            target="_blank"
                          >
                            <i class="fa-brands fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@SihinaCode" target="_blank">
                            <i class="fa-brands fa-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://github.com/ISampathI" target="_blank">
                            <i class="fa-brands fa-github"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/in/prasanna-sampath-96106226a/"
                            target="_blank"
                          >
                            <i class="fa-brands fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content-sub-title" ref={ref}>
                  Hello! I'm&nbsp;<span>Sampath</span>,
                </div>
                <div className="content-para">
                  I am a passionate full-stack developer with a strong focus on frontend
                  development. I specialize in using frameworks like React, Next.js, and Angular to
                  create dynamic and responsive web applications. With a keen eye for detail and a
                  commitment to user-friendly design, I strive to deliver top-notch applications
                  that provide excellent user experiences. Always eager to learn and grow, I
                  continuously explore new technologies and methodologies to enhance my skills. My
                  expertise includes using Tailwind CSS, styled-components, and SCSS for advanced
                  styling, as well as TypeScript for robust, maintainable code. I excel at
                  problem-solving and enjoy transforming complex data structures into efficient,
                  reusable code. Innovation drives me, and I am dedicated to staying at the
                  forefront of the industry, seeking out new challenges and opportunities to expand
                  my knowledge and capabilities.
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="tunelback-img">
        <img src={tunelBottom} alt="" />
      </div> */}

      <div class="clipped"></div>
      <div id="skills"></div>
    </div>
  );
}

export default AboutSection;
