import React, { useEffect, useRef } from "react";
import "./submarine.scss";
import submarine from "../assets/img/submarine.svg";

function Submarine(props) {
  const div1 = useRef();
  const div2 = useRef();
  const div3 = useRef();
  let div4;
  const div5 = useRef();
  const div6 = useRef();

  //   let div1, div2, div3, div4;
  let isDetectingCollision;

  function detectCollision() {
    //console.log(div1, div2, div3, div4);
    // const computedStyle = window.getComputedStyle(div1?.current);
    // if (computedStyle.display === "none") {
    //   console.log("d none");
    //   isDetectingCollision = false;
    //   div2.current.style = "width:0px; animation-name:none;";
    //   div3.current.style.animationName = "none";
    //   pulseAnimState("paused");
    //   return;
    // }
    if (isDetectingCollision) {
      //console.log("not detectCollision", isDetectingCollision);
      return;
    }
    try {
      const rect1 = div3.current.getBoundingClientRect();
      const rect2 = div4.getBoundingClientRect();

      if (rect1.top > rect2.top) {
        isDetectingCollision = true;
        //div1.current.style.animationPlayState = "paused";
        div1.current.style.setProperty(
          "--submarine-offset",
          `${Math.floor(rect1.top - rect2.top) * 2}px`
        );
        // div3.current.style.animation =
        //   "submarine-move-land-anim 20s ease forwards";
        pulseAnimState("running");
        // setTimeout(() => {
        //   console.log("animation runing");
        //   div3.current.style.animation =
        //     "submarine-move-land-anim 20s ease forwards reverse";
        //   //div1.current.style.animationPlayState = "running";
        // }, 10000);
        setTimeout(() => {
          div2.current.style = "width:20px; animation-name:none;";
        }, 1000);
        setTimeout(() => {
          console.log("animation runing2");
          div2.current.style.animation =
            "submarine-upload-bar-anim 55s 0s forwards reverse ease-out";
          //div1.current.style.animationPlayState = "running";
        }, 2000);
        setTimeout(() => {
          div2.current.style = "width:0px; animation-name:none;";
          pulseAnimState("paused");
        }, 55000);
        setTimeout(() => {
          console.log("animation runing3");
          isDetectingCollision = false;
          div3.current.style.animationName = "none";
        }, 90000);
        console.log("Collision detected!", isDetectingCollision);
      }
    } catch (e) {}
  }
  const pulseAnimState = (animState) => {
    const childElements = div5.current.children;
    const opacity = animState == "paused" ? "none" : "flex";
    for (let i = 0; i < childElements.length; i++) {
      const childElement = childElements[i];
      childElement.style = `animation-play-state: ${animState};
      display: ${opacity};`;
    }
  };
  useEffect(() => {
    isDetectingCollision = false;
    div4 = document.getElementById("skills");
    pulseAnimState("paused");
    setInterval(detectCollision, 3000);
  });
  return (
    <div
      className="Submarine submarine-move"
      id="submarine"
      ref={div1}
      onAnimationEnd={() => {
        div2.current.style.animation =
          "submarine-upload-bar-anim 10s 0s forwards ease-in";
      }}
    >
      <div className="submarine-wrap" id="submarine-wrap" ref={div3}>
        <div className="submarine-light" ref={div6}></div>
        <div className="circle-pulses" ref={div5}>
          <div class="circle-pulse circle-pulse-1"></div>
          <div class="circle-pulse circle-pulse-2"></div>
          <div class="circle-pulse circle-pulse-3"></div>
        </div>
        <img className="submarine-img" src={submarine} alt="" />
        <div
          className="submarine-upload-bar"
          ref={div2}
          onAnimationStart={() => {
            if (!isDetectingCollision) {
              div1.current.style.animationName = "none";
              div6.current.style.animationName = "none";
            }
          }}
          onAnimationEnd={() => {
            div1.current.style.animation =
              "submarine-move-down-anim 160s 0s linear forwards";
            div6.current.style.animation =
              "submarine-light-anim 5s infinite forwards linear";
          }}
        ></div>
      </div>
    </div>
  );
}

export default Submarine;
