import React, { useContext, useState } from "react";
import Header from "../../layouts/Header";
import "./home.scss";
import Land from "./land/Land";
import { ChangeNavBar, NavItemContext } from "../../helpers/context";
import SideNavBar from "../../layouts/SideNavBar";

function Home() {
  const [changeNavBar, setChangeNavBar] = useState(false);
  const [navItem, setNavItem] = useState(undefined);

  return (
    <ChangeNavBar.Provider value={{ changeNavBar, setChangeNavBar }}>
      <NavItemContext.Provider value={{ navItem, setNavItem }}>
        <div className="Home">
          <Land />
          <SideNavBar />
          {/* <div className="m"></div>
        <div className="s"></div> */}
        </div>
      </NavItemContext.Provider>
    </ChangeNavBar.Provider>
  );
}

export default Home;
