import img1 from "../assets/img/portfolio/img1.webp";
import img2 from "../assets/img/portfolio/img2.webp";
import img3 from "../assets/img/portfolio/img3.webp";
import img4 from "../assets/img/portfolio/img4.webp";
import img5 from "../assets/img/portfolio/img5.webp";
import img6 from "../assets/img/portfolio/img6.webp";
import img7 from "../assets/img/portfolio/img7.webp";
import img8 from "../assets/img/portfolio/img8.webp";
import img9 from "../assets/img/portfolio/img9.webp";
import img10 from "../assets/img/portfolio/img10.webp";

export const Portfolios = [
  {
    title: "Gaming Cafe Management System",
    category: ["web"],
    img: img2,
    desc: "This is a web-based management system designed for gaming cafes. It provides features for users to book PCs, view available games, and see popular games and PCs. Admins have access to additional features including game and PC management, user management, package management, and reservation management. The frontend is created using HTML, CSS, and JavaScript, while the backend is built on Laravel and the database used is MySQL.",
    tech: [1, 2, 3, 9],
    links: [
      {
        type: 1,
        link: "https://github.com/ISampathI/gaming-cafe",
      },
    ],
  },
  {
    title: "Service Selling Platform",
    category: ["web"],
    img: img7,
    desc: "HireNow.lk is a university group project that focuses on creating an online service selling platform. This platform provides users with an opportunity to buy and sell various physical services. With HireNow.lk, users can share their skills and talents with the online world and earn money while doing so. The project includes the development of a user-friendly interface, advanced features.",
    tech: [1, 4, 5, 6, 7, 13],
    links: [
      {
        type: 1,
        link: "https://github.com/ISampathI/service-selling-web",
      },
    ],
  },
  {
    title: "Type racing game",
    category: ["web"],
    img: img1,
    desc: "This typing game project is a web-based game developed using React for the frontend and Node.js with Socket.io for the backend. It offers players a real-time, multiplayer typing experience, with a sleek and user-friendly interface. The game includes a real-time scoreboard that displays the scores of each player, making it competitive and engaging. The use of Socket.io ensures seamless and low-latency communication between the server and clients, resulting in a smooth gaming experience.",
    tech: [1, 4, 5, 6, 7, 13, 11],
  },
  {
    title: "Movie Browsing Web",
    category: ["web"],
    img: img8,
    desc: "This ReactJS-based movie browsing web application is designed to provide an intuitive and user-friendly platform for users to browse and search for movies. The application offers a variety of features that make it easy for users to find the movies they are interested in.",
    tech: [1, 4, 5],
    links: [
      {
        type: 1,
        link: "https://github.com/ISampathI/movie-app",
      },
    ],
  },
  {
    title: "RCMD - Remote Command Prompt",
    category: ["mobile"],
    img: img4,
    tech: [19],
    desc: "RCMD is a powerful tool that allows you to remotely access the command prompt on your Windows computer from your mobile phone or tablet. This makes it easy to send and receive data, as well as control your computer using cmd commands.With hundreds of cmd commands available, RCMD supports the majority of them, giving you flexibility and control when accessing your Windows computer. Plus, RCMD is easy to use and can be a valuable addition to your toolbox if you need to manage your computer remotely.",
    links: [
      {
        type: 2,
        link: "https://play.google.com/store/apps/details?id=com.sihinacode.rcmd",
      },
    ],
  },
  {
    title: "DTV Remote",
    category: ["mobile"],
    img: img5,
    tech: [19],
    desc: "DTVremote is an innovative mobile application that allows you to control your Dialog TV decoder using your mobile phone. Whether your physical remote is damaged or out of reach, you can rely on DTVremote to provide all the necessary functionality and advanced features that your physical remote offers.",
    links: [
      {
        type: 2,
        link: "https://play.google.com/store/apps/details?id=com.sihinacode.dtvremote",
      },
    ],
  },
  {
    title: "Plant Care App",
    category: ["mobile"],
    img: img6,
    desc: "PlatCare is a Flutter mobile application that allows users to remotely monitor and control the health of their plants. It enables the user to connect to an Arduino or NodeMCU microcontroller from any location through the internet. The app provides real-time updates on the temperature and moisture level of the plants. Additionally, users can control the water pump speed and turn it on and off as needed. They can also view the last watering time to keep track of their plant's hydration.",
    tech: [19],
    links: [
      {
        type: 1,
        link: "https://github.com/ISampathI/plantcare-mobile-app",
      },
    ],
  },
  {
    title: "Movie theater ticket booking system",
    category: ["desktop"],
    img: img3,
    desc: "This desktop application, developed in C#, is a comprehensive solution for cashiers at movie theaters to book seats for customers. With the help of this application, cashiers can easily view available seats and select seats for customers.  Another key feature of this application is its ability to print receipts for customers, providing them with a record of their purchase. With its user-friendly interface and a wide range of features, this application streamlines the ticketing process and helps cashiers to provide exceptional customer service.",
    tech: [15],
    links: [
      {
        type: 1,
        link: "https://github.com/ISampathI/movie-theater-app",
      },
    ],
  },
  {
    title: "Share",
    category: ["desktop"],
    img: img9,
    desc: "Share is a Python-based software designed for effortless file sharing between computers within a LAN network. With a user-friendly interface, Share eliminates the complexities of setup and enables seamless file transfers. It allows users to exchange files of any size or format, promoting efficient collaboration and communication Experience the convenience of Share as it revolutionizes file sharing over LAN networks, providing a hassle-free solution for all your file transfer needs.",
    tech: [14, 18],
    // links: [
    //   {
    //     type: 1,
    //     link: "https://github.com/ISampathI/movie-theater-app",
    //   },
    // ],
  },
  {
    title: "PC2ARDU",
    category: ["desktop"],
    img: img10,
    desc: "PC2ARDU is a feature-rich Arduino control software designed for seamless USB or Bluetooth connectivity with a computer. Its user-friendly interface and built-in Serial Monitor make it easy to interact with Arduino boards. With PC2ARDU, users can monitor and debug projects effortlessly. The software offers unique capabilities such as controlling Arduino using a computer keyboard and vice versa, enabling dynamic interactions. It also provides IP camera client integration for incorporating live video feeds into Arduino projects. PC2ARDU combines convenience and versatility, making it an ideal choice for Arduino enthusiasts seeking efficient control and expanded functionality in their projects.",
    tech: [14, 18],
    // links: [
    //   {
    //     type: 1,
    //     link: "https://github.com/ISampathI/movie-theater-app",
    //   },
    // ],
  },
];
