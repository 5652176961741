import React, { useContext, useState } from "react";
import "./portfolioSection.scss";
import { Route, Routes, useNavigate } from "react-router-dom";
import portfolioBoard from "../../../../assets/img/portfolionameback.svg";
import hologram from "../../../../assets/img/hologram.svg";
import hologramLight from "../../../../assets/img/hologramlight.svg";
import water from "../../../../assets/img/water.svg";
import grassBack from "../../../../assets/img/grassback.svg";
import portfolioBottom from "../../../../assets/img/portfoliobottom.svg";
import portfolioTop from "../../../../assets/img/portfoliotop.svg";
import googleplay from "../../../../assets/img/googleplay.webp";
import PhotoAlbum from "react-photo-album";
import Gallery from "../../../../components/Gallery";
import { Portfolios } from "../../../../helpers/portfolios";
import { motion } from "framer-motion";
import Modal from "react-modal";
import { useInView } from "react-intersection-observer";
import { NavItemContext } from "../../../../helpers/context";
import { MySkills } from "../../../../helpers/skills";
import { Tooltip } from "react-tooltip";

function PortfolioSection() {
  const navigate = useNavigate();

  const [portfolio, setPortfolio] = useState(Portfolios);
  const [portfolioType, setPortfolioType] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activePortfolio, setActivePortfolio] = useState(Portfolios[0]);

  const { navItem, setNavItem } = useContext(NavItemContext);

  const { ref, inView, entry } = useInView({
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && navItem == undefined) {
        navigate("#portfolio");
      } else if (navItem == "portfolio") {
        navigate("#portfolio");
        setNavItem(undefined);
      }
    },
  });

  const changePortfolioList = (category) => {
    setPortfolioType(category);
    if (category != "all") {
      const a = Portfolios.filter((item) => item.category.includes(category));
      console.log(a);
      setPortfolio(a);
    } else {
      setPortfolio(Portfolios);
    }
  };

  const animVariant = {
    initial: { opacity: 0, translateY: 20 },
    whileInView: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 1,
      },
    },
  };

  const animVariant2 = {
    initial: { scale: 0, opacity: 0.5 },
    whileInView: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <div className="PortfolioSection" id="portfolio">
      <img src={portfolioTop} className="portfoliotop-img" alt="" />
      <img src={water} className="water-img" alt="" />
      <div className="grassback-img" alt=""></div>
      <dev className="portfolioboard-img">
        <div className="portfolioboard-img-wrap">
          <div className="card-chain"></div>
          <img className="board-img" src={portfolioBoard} alt="" />
          <img className="hologram-img" src={hologram} alt="" />
          <img className="hologramlight-img" src={hologramLight} alt="" />
          <div className="section-title">
            <span>Port</span>folio
          </div>
        </div>
      </dev>
      <div className="protfolio-nav" ref={ref}>
        <ul>
          <li
            onClick={() => {
              changePortfolioList("all");
            }}
            className={
              portfolioType == "all" ? "active-portfolio-nav-item" : ""
            }
          >
            All
          </li>
          <li
            onClick={() => {
              changePortfolioList("web");
            }}
            className={
              portfolioType == "web" ? "active-portfolio-nav-item" : ""
            }
          >
            Web
          </li>
          <li
            onClick={() => {
              changePortfolioList("mobile");
            }}
            className={
              portfolioType == "mobile" ? "active-portfolio-nav-item" : ""
            }
          >
            Mobile
          </li>
          <li
            onClick={() => {
              changePortfolioList("desktop");
            }}
            className={
              portfolioType == "desktop" ? "active-portfolio-nav-item" : ""
            }
          >
            Desktop
          </li>
        </ul>
      </div>
      <motion.div
        className="section-container"
        variants={animVariant}
        initial="initial"
        whileInView="whileInView"
        viewport={{ once: true }}
      >
        {/* <Gallery images={Portfolios} /> */}
        {portfolio.map((item) => {
          return (
            <>
              <div className="portfolio-card">
                <div className="card-title">{item.title}</div>
                <div
                  className="more-btn"
                  onClick={() => {
                    setActivePortfolio(item);
                    setIsModalOpen(true);
                  }}
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <img src={item.img} alt="" />
                <div className="card-chain"></div>
              </div>
            </>
          );
        })}
      </motion.div>
      <img src={portfolioBottom} className="portfoliobottom-img" alt="" />
      <Modal
        isOpen={isModalOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        className="Modal"
        overlayClassName="modal-overlay"
        contentLabel="portfolio Details Modal"
      >
        <motion.div
          className="portfolio-details-modal"
          variants={animVariant2}
          initial="initial"
          whileInView="whileInView"
        >
          <div className="portfolio-d-img">
            <img src={activePortfolio.img} alt="" />
          </div>
          <div className="portfolio-d-title">{activePortfolio.title}</div>
          <div className="portfolio-d-details">{activePortfolio.desc}</div>
          <div className="portfolio-d-tech-list">
            {activePortfolio.tech &&
              MySkills.filter((item) =>
                activePortfolio.tech.includes(item.id)
              ).map((item) => (
                <>
                  <div
                    className="portfolio-d-tech"
                    data-tooltip-id="dtech-tooltip"
                    data-tooltip-content={item.name}
                  >
                    <img src={item.img} alt="" />
                  </div>
                  <Tooltip id="dtech-tooltip" place="top" />
                </>
              ))}
            <div className="portfolio-d-link-list">
              {activePortfolio.links &&
                activePortfolio.links.map((item) => (
                  <a
                    href={item.link}
                    target="_blank"
                    className={
                      item.type == 2
                        ? "portfolio-d-link portfolio-d-link-b"
                        : "portfolio-d-link portfolio-d-link-a"
                    }
                  >
                    {item.type == 1 || item.type == 3 ? (
                      <>
                        <i
                          class={
                            item.type == 1
                              ? "fa-brands fa-github"
                              : "fa-solid fa-globe"
                          }
                        ></i>
                        <p>View on GitHub</p>
                      </>
                    ) : (
                      <img src={googleplay}></img>
                    )}
                  </a>
                ))}
            </div>
          </div>
          <div
            onClick={() => {
              setIsModalOpen(false);
            }}
            className="portfolio-d-close-btn"
          >
            <i class="fa-solid fa-x"></i>
          </div>
        </motion.div>
      </Modal>
    </div>
  );
}

export default PortfolioSection;
