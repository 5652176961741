import React, { useContext, useRef, useState } from "react";
import "./contact.scss";
import { motion } from "framer-motion";
import blackhole from "../../../../assets/img/blackhole.png";
import spaceshiptop from "../../../../assets/img/spaceshiptop.svg";
import bhouterwheel2 from "../../../../assets/img/bhouterwheel2.svg";
import bhouterwheel1 from "../../../../assets/img/bhouterwheel1.svg";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import contactstop from "../../../../assets/img/contactstop.svg";
import contactsbottom from "../../../../assets/img/contactsbottom.svg";
import { NavItemContext } from "../../../../helpers/context";

function ContactSection() {
  const formRef = useRef();
  const blackholeRef = useRef();

  const navigate = useNavigate();

  const { navItem, setNavItem } = useContext(NavItemContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { ref, inView, entry } = useInView({
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && navItem == undefined) {
        navigate("#contacts");
      } else if (navItem == "contacts") {
        navigate("#contacts");
        setNavItem(undefined);
      }
    },
  });

  const animVariant = {
    initial: { opacity: 0, translateY: 20 },
    whileInView: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 1,
      },
      viewport: { once: true },
    },
  };

  const sendWhatsAppMessage = () => {
    const messageString = `Name: ${name}\nEmail: ${email}\n\n${message}`;

    const encodedMessage = encodeURIComponent(messageString);

    const phoneNumber = "94719428157";
    const apiLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(apiLink);
  };

  const submitHandler = async () => {
    if (!name || !email || !message) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch("https://sihinacode.com/api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          endpoint: "contact",
          name,
          email,
          message,
        }),
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className="ContactSection" id="contacts">
      <div className="section-container">
        <div className="top-container">
          <img src={contactstop} className="contact-top-img" />
          <motion.div
            className="section-title"
            ref={ref}
            variants={animVariant}
            initial="initial"
            whileInView="whileInView"
            viewport={{ once: true }}
          >
            Contact <span>Me</span>
          </motion.div>
          <motion.p
            variants={animVariant}
            initial="initial"
            whileInView="whileInView"
            viewport={{ once: true }}
          >
            Thank you for taking the time to visit my portfolio. If you have any questions, comments
            or would like to work together, please don't hesitate to get in touch using the form
            below. I look forward to hearing from you!
          </motion.p>
          <div className="contact-details">
            <motion.div
              className="detail-card"
              variants={animVariant}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
            >
              <i class="fa-solid fa-envelope"></i>
              <p>sampathrdps@gmail.com</p>
            </motion.div>
            <motion.div
              className="detail-card"
              variants={animVariant}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
            >
              <i class="fa-solid fa-phone"></i>
              <p>+94 71 942 8157</p>
            </motion.div>
            <motion.div
              className="detail-card"
              variants={animVariant}
              initial="initial"
              whileInView="whileInView"
              viewport={{ once: true }}
            >
              <i class="fa-solid fa-location-dot"></i>
              <p>C/9/1, Maligawaththa, Kinivita, Mahapallegama</p>
            </motion.div>
          </div>
        </div>
        <div className="bottom-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            action=""
            ref={formRef}
            onBlur={(e) => {
              //blackholeRef.current.addEventListener("animationend", handleAnimationEnd);
              //blackholeRef.current.classList.add("animate-blackhole-exit");
            }}
            onFocus={() => {
              console.log("hi");
              //blackholeRef.current.classList.remove("animate-blackhole-exit");
              //blackholeRef.current.classList.add("animate-blackhole");
            }}
          >
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>
            <div className="message-send-btn" onClick={() => submitHandler()}>
              <div className="whatsapp-btn">
                <p>Send Message</p>
                {loading && (
                  <div className="loader"></div>
                )}
              </div>
            </div>
          </form>
        </div>
        <img src={contactsbottom} alt="" className="contact-bottom-img" />
      </div>
    </div>
  );
}

export default ContactSection;
