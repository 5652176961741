import React from "react";
import "./gallery.scss";

function Gallery(props) {
  const images = props.images;
  console.log(images);
  const chunkSize = Math.ceil(images.length / 4);
  const list1 = images.slice(0, chunkSize);
  const list2 = images.slice(chunkSize, chunkSize * 2);
  const list3 = images.slice(chunkSize * 2, chunkSize * 3);
  const list4 = images.slice(chunkSize * 3);

  return (
    <div class="gallery">
      <div class="gallery__column">
        {list1.map((item) => {
          return (
            <dev
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                  src={item.img}
                  alt="Portrait by Jessica Felicio"
                  class="gallery__image"
                ></img>
                <figcaption class="gallery__caption">
                  Portrait by Jessica Felicio
                </figcaption>
              </figure>
            </dev>
          );
        })}
      </div>
      <div class="gallery__column">
        {list2.map((item) => {
          return (
            <a
              href="https://unsplash.com/@jeka_fe"
              target="_blank"
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                  src={item.img}
                  alt="Portrait by Jessica Felicio"
                  class="gallery__image"
                ></img>
                <figcaption class="gallery__caption">
                  Portrait by Jessica Felicio
                </figcaption>
              </figure>
            </a>
          );
        })}
      </div>
      <div class="gallery__column">
        {list3.map((item) => {
          return (
            <a
              href="https://unsplash.com/@jeka_fe"
              target="_blank"
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                  src={item.img}
                  alt="Portrait by Jessica Felicio"
                  class="gallery__image"
                ></img>
                <figcaption class="gallery__caption">
                  Portrait by Jessica Felicio
                </figcaption>
              </figure>
            </a>
          );
        })}
      </div>
      <div class="gallery__column">
        {list4.map((item) => {
          return (
            <a
              href="https://unsplash.com/@jeka_fe"
              target="_blank"
              class="gallery__link"
            >
              <figure class="gallery__thumb">
                <img
                  src={item.img}
                  alt="Portrait by Jessica Felicio"
                  class="gallery__image"
                ></img>
                <figcaption class="gallery__caption">
                  Portrait by Jessica Felicio
                </figcaption>
              </figure>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Gallery;
