import React, { useContext } from "react";
import "./sideNavBar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { NavItemContext } from "../helpers/context";

function SideNavBar() {
  let location = useLocation();

  const { navItem, setNavItem } = useContext(NavItemContext);

  function scrollToElement(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="SideNavBar">
      <nav>
        <ul>
          <NavHashLink
            smooth
            to="#home"
            onClick={() => {
              setNavItem("home");
            }}
            className={
              `${location.hash}` === "#home" || `${location.hash}` === ""
                ? "active-nav"
                : ""
            }
          >
            <li>
              <i class="fa-solid fa-house-chimney-window"></i>
            </li>
          </NavHashLink>
          <NavHashLink
            smooth
            to="#about"
            onClick={() => {
              setNavItem("about");
            }}
            className={`${location.hash}` === "#about" ? "active-nav" : ""}
          >
            <li>
              <i class="fa-solid fa-user"></i>
            </li>
          </NavHashLink>
          <NavHashLink
            smooth
            to="#skills"
            onClick={() => {
              setNavItem("skills");
            }}
            className={`${location.hash}` === "#skills" ? "active-nav" : ""}
          >
            <li>
              <i class="fa-solid fa-hand-spock"></i>
            </li>
          </NavHashLink>
          <NavHashLink
            smooth
            to="#portfolio"
            onClick={() => {
              setNavItem("portfolio");
            }}
            className={`${location.hash}` === "#portfolio" ? "active-nav" : ""}
          >
            <li>
              <i class="fa-solid fa-briefcase"></i>
            </li>
          </NavHashLink>
          <NavHashLink
            smooth
            to="#contacts"
            onClick={() => {
              setNavItem("contacts");
            }}
            className={`${location.hash}` === "#contacts" ? "active-nav" : ""}
          >
            <li>
              <i class="fa-solid fa-address-book"></i>
            </li>
          </NavHashLink>
        </ul>
      </nav>
    </div>
  );
}

export default SideNavBar;
