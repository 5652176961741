import React, { useContext, useEffect, useRef } from "react";
import "./land.scss";
import HomeSection from "./sections/HomeSection";
import AboutSection from "./sections/AboutSection";
import PortfolioSection from "./sections/PortfolioSection";
import ContactSection from "./sections/ContactSection";
import ServicesSection from "./sections/ServicesSection";
import { ChangeNavBar } from "../../../helpers/context";
import SkillsSection from "./sections/SkillsSection";
import { Fireworks } from "@fireworks-js/react";
import aboutmetopl from "../../../assets/img/aboutmetopl.svg";
import aboutmetopr from "../../../assets/img/aboutmetopr.svg";
import citybottom from "../../../assets/img/citybottom.svg";
import submarine from "../../../assets/img/submarine.svg";
import skillsBackDrop from "../../../assets/img/skillsbackdrop1.png";
import grass from "../../../assets/img/grass.png";
import Footer from "../../../layouts/Footer";
import { useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Turtle from "../../../components/Turtle";
import Submarine from "../../../components/Submarine";
import { Tooltip } from "react-tooltip";

function Land() {
  const { changeNavBar, setChangeNavBar } = useContext(ChangeNavBar);

  const div4 = useRef();

  var handleScroll = (event) => {
    const scrollTop = event.target.scrollTop;
    if (scrollTop >= 100) {
      setChangeNavBar(true);
    } else if (scrollTop < 100) {
      setChangeNavBar(false);
    }
  };

  return (
    <div className="Land" onScroll={handleScroll}>
      <HomeSection />
      <div className="AboutTop">
        <div className="about-top-grass"></div>
        <div id="about"></div>
        <img src={citybottom} className="citybottom-img" alt="" />
        <div className="aboutmetopl-img"></div>
        <Turtle className="turtle1" />
        <Turtle className="turtle2" />
        {/* <img src={aboutmetopr} className="aboutmetopr-img" alt="" /> */}
      </div>
      <AboutSection />
      <SkillsSection />
      <ServicesSection />
      <PortfolioSection />
      <ContactSection />
      <Footer />
      <Submarine />
      <div className="skillsbackdrop-img" alt=""></div>
    </div>
  );
}

export default Land;
