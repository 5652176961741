import React, { useContext, useRef, useState } from "react";
import "./homeSection.scss";
import hero from "../../../../assets/img/hero.webp";
import city from "../../../../assets/img/city.svg";
import airship from "../../../../assets/img/airship.svg";
import firework from "../../../../assets/img/firework.svg";
import { motion } from "framer-motion";
import { Fireworks } from "@fireworks-js/react";
import { TypeAnimation } from "react-type-animation";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import cv from "../../../../assets/documents/Prasanna_Sampath_CV_2024.pdf";
import { NavItemContext } from "../../../../helpers/context";

const roleList = [
  "a full stack developer",
  "a mobile developer",
  "a desktop developer",
];

function HomeSection() {
  const [discoverMode, setDiscoverMode] = useState(false);
  const [role, setRole] = useState(roleList[1]);

  const { navItem, setNavItem } = useContext(NavItemContext);

  const typeText = useRef();
  const navigate = useNavigate();

  const { ref, inView, entry } = useInView({
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && navItem == undefined) {
        navigate("#home");
      } else if (navItem == "home") {
        navigate("#home");
        setNavItem(undefined);
      }
    },
  });

  return (
    <div className="HomeSection" id="home">
      <div className="flashit"></div>
      <img src={airship} className="airship-img" alt="" />
      <img src={city} className="city-img" alt="" />
      <img src={firework} className="firework-img" alt="" />
      {/* <img src={cave} className="cave-img" alt="" /> */}
      {/* <Fireworks
        options={{
          delay: {
            min: 30,
            max: 60,
          },
          mouse: {
            click: true,
            move: false,
            max: 1,
          },
          rocketsPoint: {
            min: 0,
            max: 100,
          },
        }}
        style={{
          top: 0,
          right: 0,
          width: "30%",
          height: "98%",
          position: "absolute",
        }}
      /> */}
      {/* <div className="hero hero-l">
        <img src={herol} alt="" />
      </div> */}
      <div
        className="hero-content"
        style={{ display: discoverMode ? "none" : "flex" }}
      >
        <div className="text-container">
          <div className="left-container">
            <div className="hero-title">
              <p ref={ref}>HI</p>{" "}
              <div className="box">{/* <p>Desktop Development</p> */}</div>
            </div>
            <div className="hero-sub-title">
              I am{" "}
              <TypeAnimation
                key={role}
                ref={typeText}
                sequence={[
                  roleList[0],
                  1000,
                  roleList[1],
                  1000,
                  roleList[2],
                  1000,
                ]}
                wrapper="span"
                cursor={false}
                repeat={Infinity}
                className="type-animation"
              />
            </div>
            <div className="hero-sub-title-2">Sampath</div>
            <a href={cv} target="_blank" className="download-cv-btn">My Resume</a>
          </div>
          <div className="right-container">
            <p>Desktop developer</p>
          </div>
        </div>
        <nav>
          <ul>
            <li className="nav-item active-nav-item">
              {"< "}Home{" >"}
            </li>
            <li className="nav-item">About</li>
            <li className="nav-item">My Works</li>
            <li className="nav-item">Contacts</li>
          </ul>
        </nav>
      </div>
      <div className="hero">
        <img src={hero} alt="" />
      </div>
    </div>
  );
}

export default HomeSection;
