import React from "react";
import "./turtle.scss";

function Turtle(props) {
  return (
    <div className={`Turtle ${props.className}`}>
      <svg
        width="59"
        height="36"
        viewBox="0 0 109 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="turtle"
      >
        <path
          className="turtle-head"
          d="M45.9106 3.8183C45.3228 4.2867 42.4212 4.8124 41.0439 5.0167C38.2463 9.0203 41.3842 10.0436 43.3029 10.0547C50.9879 9.40119 55.1375 4.68985 56.2516 2.41588C50.4682 -1.0149 46.6453 3.23281 45.9106 3.8183Z"
          fill="black"
        />
        <path
          className="turtle-fleg"
          d="M30.6597 33.6192C32.0076 32.2494 33.5715 23.783 34.1849 19.7211C34.6841 16.7498 39.8951 13.4823 42.4382 12.22C42.0398 22.2958 34.4199 30.6844 30.6597 33.6192Z"
          fill="black"
        />
        <path
          className="turtle-bleg"
          d="M3.09234 32.3028C6.93272 30.721 9.6313 26.1679 10.5005 24.0891C13.0417 21.9901 15.5992 23.5683 16.5603 24.6198C12.7285 31.2218 5.98506 32.4926 3.09234 32.3028Z"
          fill="black"
        />
        <path
          className="turtle-body"
          d="M7.66758 24.5916C10.4578 15.3759 22.2603 -1.23103 47.1488 6.06667C43.6718 14.3819 30.9078 29.7282 7.66758 24.5916Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default Turtle;
