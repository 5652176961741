import React from "react";
import "./footer.scss";

function Footer() {
  return (
    <div className="Footer">
      <div className="rel-links">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/prasannasampath.sampath.73/"
              target="_blank"
            >
              <i class="fa-brands fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@SihinaCode" target="_blank">
              <i class="fa-brands fa-youtube"></i>
            </a>
          </li>
          <li>
            <a href="https://github.com/ISampathI" target="_blank">
              <i class="fa-brands fa-github"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/prasanna-sampath-96106226a/"
              target="_blank"
            >
              <i class="fa-brands fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
      <p>&copy; 2023 Sampath. All rights reserved.</p>
    </div>
  );
}

export default Footer;
