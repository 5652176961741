import skillHtmlImg from "../assets/img/skills/html.webp";
import skillJsImg from "../assets/img/skills/js.webp";
import skillNodeJsImg from "../assets/img/skills/nodejs.webp";
import skillReactImg from "../assets/img/skills/react.webp";
import skillNextJsImg from "../assets/img/skills/next.webp";
import skillPythonImg from "../assets/img/skills/python.webp";
import skillCppImg from "../assets/img/skills/cpp.webp";
import skillCssImg from "../assets/img/skills/css.webp";
import skillPhpImg from "../assets/img/skills/php.webp";
import skillJavaImg from "../assets/img/skills/java.webp";
import skillSassImg from "../assets/img/skills/sass.webp";
import skillLaravelImg from "../assets/img/skills/laravel.webp";
import skillFlutterImg from "../assets/img/skills/flutter.webp";
import skillMysqlImg from "../assets/img/skills/mysql.webp";
import skillSqliteImg from "../assets/img/skills/sqlite.webp";
import skillCsharpImg from "../assets/img/skills/csharp.webp";
import skillFirebaseImg from "../assets/img/skills/firebase.webp";
import skillSocketioImg from "../assets/img/skills/socketio.webp";
import skillMongodbImg from "../assets/img/skills/mongodb.webp";
import skillExpressImg from "../assets/img/skills/express.webp";
import skillFlaskImg from "../assets/img/skills/flask.webp";
import skillAngularImg from "../assets/img/skills/angular.webp";
import skillNestjsImg from "../assets/img/skills/nestjs.webp";
import skillPostgresqlImg from "../assets/img/skills/postgresql.webp";

export const MySkills = [
  {
    id: 1,
    name: "HTML",
    category: ["web"],
    img: skillHtmlImg,
  },
  {
    id: 2,
    name: "CSS",
    category: ["web"],
    img: skillCssImg,
  },
  {
    id: 3,
    name: "JS",
    category: ["web"],
    img: skillJsImg,
  },
  {
    id: 4,
    name: "sass",
    category: ["web"],
    img: skillSassImg,
  },
  {
    id: 5,
    name: "React",
    category: ["web"],
    img: skillReactImg,
  },
  {
    id: 55,
    name: "NextJs",
    category: ["web"],
    img: skillNextJsImg,
  },
  {
    id: 21,
    name: "Angular",
    category: ["web"],
    img: skillAngularImg,
  },
  {
    id: 6,
    name: "NodeJS",
    category: ["web"],
    img: skillNodeJsImg,
  },
  {
    id: 7,
    name: "Express",
    category: ["web"],
    img: skillExpressImg,
  },
  {
    id: 22,
    name: "NestJS",
    category: ["web"],
    img: skillNestjsImg,
  },
  {
    id: 8,
    name: "PHP",
    category: ["web"],
    img: skillPhpImg,
  },
  {
    id: 9,
    name: "Laravel",
    category: ["web"],
    img: skillLaravelImg,
  },
  {
    id: 10,
    name: "Flask",
    category: ["web"],
    img: skillFlaskImg,
  },
  {
    id: 11,
    name: "socketIo",
    category: ["web"],
    img: skillSocketioImg,
  },
  {
    id: 12,
    name: "MySQL",
    category: ["web", "desktop"],
    img: skillMysqlImg,
  },
  {
    id: 23,
    name: "PostgreSQL",
    category: ["web"],
    img: skillPostgresqlImg,
  },
  {
    id: 13,
    name: "mongoDB",
    category: ["web"],
    img: skillMongodbImg,
  },
  //Desktop
  {
    id: 14,
    name: "Python",
    category: ["desktop"],
    img: skillPythonImg,
  },
  {
    id: 15,
    name: "C#",
    category: ["desktop"],
    img: skillCsharpImg,
  },
  {
    id: 16,
    name: "C++",
    category: ["desktop"],
    img: skillCppImg,
  },
  {
    id: 17,
    name: "Java",
    category: ["desktop"],
    img: skillJavaImg,
  },
  {
    id: 18,
    name: "SQLite",
    category: ["desktop"],
    img: skillSqliteImg,
  },
  //Mobile
  {
    id: 19,
    name: "Flutter",
    category: ["mobile"],
    img: skillFlutterImg,
  },
  {
    id: 20,
    name: "Firebase",
    category: ["mobile"],
    img: skillFirebaseImg,
  },
];
