import React from "react";
import "./servicesSection.scss";
import { motion } from "framer-motion";

function ServicesSection() {
  const animVariant = {
    initial: { opacity: 0, translateY: 20 },
    whileInView: {
      opacity: 1,
      translateY: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className="ServicesSection">
      <motion.div
        className="section-title"
        variants={animVariant}
        initial="initial"
        whileInView="whileInView"
        viewport={{ once: true }}
      >
        My Services
      </motion.div>
      <motion.div
        className="sub-title"
        variants={animVariant}
        initial="initial"
        whileInView="whileInView"
        viewport={{ once: true }}
      >
        Helping You Build Your Dream Project
      </motion.div>
      <div className="section-container">
        <motion.div
          className="service-card"
          variants={animVariant}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
        >
          <i
            class="fa-solid fa-layer-group"
          ></i>
          <div className="service-title">Fullstack Development</div>
          <div className="service-details">
            I have expertise in building web applications that require both
            frontend and backend technologies. As a fullstack developer, I can
            design user interfaces, create APIs, and develop databases to store
            data.
          </div>
        </motion.div>
        <motion.div
          className="service-card"
          variants={animVariant}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
        >
          <i class="fa-solid fa-mobile-screen"></i>
          <div className="service-title">Mobile Development</div>
          <div className="service-details">
            I have experience in building mobile applications for Android
            platforms. I can develop user-friendly interfaces, integrate with
            backend APIs, and ensure app performance and reliability.
          </div>
        </motion.div>
        <motion.div
          className="service-card"
          variants={animVariant}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
        >
          <i class="fa-solid fa-desktop"></i>
          <div className="service-title">Desktop Development</div>
          <div className="service-details">
            I have experience in developing desktop applications using a variety
            of technologies, such as python, C#, C++ and Qt. I can design user
            interfaces, develop cross-platform applications, and ensure
            compatibility with different operating systems.
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ServicesSection;
