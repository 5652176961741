import React, { useContext, useEffect } from "react";
import "./header.scss";
import { motion, useScroll } from "framer-motion";
import { ChangeNavBar } from "../helpers/context";

function Header() {
  const { changeNavBar, setChangeNavBar } = useContext(ChangeNavBar);

  function scrollToElement(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <motion.div className="Header">
      {!changeNavBar && (
        <motion.div className="logo" exit={{ opacity: 0 }}>
          <motion.div className="logo-box"></motion.div> Sampath
        </motion.div>
      )}
      <nav className={changeNavBar ? "short-nav" : ""}>
        <div className="nav-items">
          <div className="nav-item nav-item-active">
            {`<`}
            <motion.span
              initial={{ marginInline: "0.2rem" }}
              whileHover={{ marginInline: "0.3rem" }}
              onClick={() => scrollToElement("HomeSection")}
            >
              Home
            </motion.span>
            {`>`}
          </div>
          <div className="nav-item" onClick={() => scrollToElement("AboutSection")}>
            <span>About</span>
          </div>
          <div className="nav-item" onClick={() => scrollToElement("PortfolioSection")}>
            <span>Portfolios</span>
          </div>
        </div>
      </nav>
      {!changeNavBar && (
        <motion.div
          whileHover={{ scale: 0.9 }}
          exit={{ opacity: 0 }}
          className="contact-nav-btn"
        >
          Contacts
        </motion.div>
      )}
    </motion.div>
  );
}

export default Header;
